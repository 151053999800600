import { createContext, ElementType, FC, HTMLAttributes, useContext } from 'react';
import { ColorVariant, FontWeight, TypographyVariant } from '../../../theme';
import { fontColor, margin, typography, weakEffect } from '../../../theme/mixins';
import { useStyles } from '../../hooks';
import { CssOverridableProps } from '../../types';

/** List of colors from the palette theme object that can be used to style icons. */
export type TextColor = 'inherit' | ColorVariant;

/** Props definition for a text component. */
export type TextProps = {
  /** How the text should be aligned. */
  align?: 'left' | 'center' | 'right';
  /** The element type of the text. */
  as?: ElementType;
  /** Whether the text element should be a block element filling the width. */
  block?: boolean;
  /** Color of the text. */
  color?: TextColor;
  /** Whether a bottom margin should be applied to the text. */
  gutter?: boolean;
  /** Whether the text should be truncated if it exceeds the line. */
  truncated?: boolean;
  /** Variant of typography applied to the text. */
  variant?: TypographyVariant;
  /** Whether the text should have a less vibrant appearance. */
  weak?: boolean;
  /** The font weight of the text, overriding the variant's weight. */
  weight?: FontWeight;
} & CssOverridableProps &
  Omit<HTMLAttributes<HTMLElement>, 'color'>;

/** Context to send through the component tree to inner components. */
const Context = createContext<TypographyVariant | null>(null);

/** Hook to get access to the data from the text variant context. */
export function useTextVariant(): TypographyVariant | null {
  return useContext(Context);
}

/** A text component that applies typography styles to text. */
export const Text: FC<TextProps> = (props) => {
  const {
    align = 'left',
    as = 'span',
    block = props.gutter || false,
    children,
    className,
    color = 'inherit',
    gutter = false,
    truncated = false,
    variant = 'body1',
    weak = false,
    weight,
    ...otherProps
  } = props;

  const textStyles = useStyles((theme) => [
    fontColor(color),
    typography(variant),
    { display: block || truncated ? 'block' : undefined },
    align !== 'left' && { textAlign: align },
    weak && weakEffect(),
    weight && { fontWeight: theme.typography.weights[weight] },
    gutter && margin(0, 0, 4, 0),
    truncated && {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  ]);

  // To render a dynamic element the variable needs to be capitalized.
  const Element = as;

  return (
    <Context.Provider value={variant}>
      <Element className={className} css={textStyles} {...otherProps}>
        {children}
      </Element>
    </Context.Provider>
  );
};

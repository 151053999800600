import { mergeProps } from '@react-aria/utils';
import { ButtonHTMLAttributes } from 'react';
import { InteractionsState, useInteractions } from './useInteractions';

/** Props definition for the `useButton` hook. */
export type UseButtonProps = {
  /** Label of the button element for screen readers. */
  'aria-label'?: string;
  /** Whether the button element should be disabled. */
  disabled?: boolean;
  /** Callback fired when the button is pressed. */
  onPress?: () => void;
  /** Type of button. */
  type?: 'button' | 'submit' | 'reset';
};

/** State from the `useButton` hook providing all you need to create a button. */
export type ButtonState = {
  /** Props to be spread on the button element. */
  buttonProps: ButtonHTMLAttributes<HTMLButtonElement>;
  /** Whether the button element is disabled. */
  disabled: boolean;
} & Omit<InteractionsState, 'focusProps' | 'hoverProps' | 'pressProps'>;

/** Hook for building an accessibility-friendly button. */
export function useButton(props: UseButtonProps): ButtonState {
  const { disabled = false, onPress, type = 'button' } = props;
  const { focusProps, hoverProps, pressProps, ...interactionsState } = useInteractions({ disabled, onPress });

  return {
    buttonProps: mergeProps(focusProps, hoverProps, pressProps, { disabled, type }),
    ...interactionsState,
  };
}

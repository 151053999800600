import { createContext, ReactNode, useContext } from 'react';
import { useControllableValue } from '../../core';

/** Props definition for a radio button group component. */
export type RadioGroupProps = {
  /** Contents of the radio button group. */
  children?: ReactNode;
  /** The initial value of the radio button group (uncontrolled). */
  defaultValue?: string;
  /** Name attribute of the input elements. */
  name?: string;
  /** Callback fired when the state is changed. */
  onChange?: (value: string) => void;
  /** The value of the radio button group (controlled). */
  value?: string;
};

/** Data returned from a radio button group context. */
export type RadioGroupContext = Pick<RadioGroupProps, 'name' | 'onChange' | 'value'>;

/** Context to send through the component tree to inner radio buttons. */
export const Context = createContext<RadioGroupContext | null>(null);

/** Hook to get access to the data from the radio group context. */
export function useRadioGroup(): RadioGroupContext | null {
  return useContext(Context);
}

/** Radio group is a helpful wrapper making it more easy to work with multiple radio buttons. */
export const RadioGroup = (props: RadioGroupProps) => {
  const { children, defaultValue, name } = props;

  const [value, onChange] = useControllableValue({
    value: props.value,
    defaultValue: defaultValue,
    onChange: props.onChange,
  });

  return <Context.Provider value={{ name, onChange, value }}>{children}</Context.Provider>;
};

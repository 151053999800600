import { css, Theme } from '@emotion/react';
import { ColorVariant } from '../colors';
import { TypographyVariant } from '../typography';

/** Apply a font color to an element. */
export const typography = (variant: TypographyVariant) => ({ typography }: Theme) => {
  return css(typography.variants[variant]);
};

/** Apply a font color to an element. */
export const fontColor = (color: 'inherit' | ColorVariant = 'darkGray') => ({ colors }: Theme) => {
  return css({ color: color === 'inherit' ? 'currentColor' : colors.text(color) });
};

/** Apply an inverted font color to an element. */
export const fontColorInverted = () => css({ color: 'white' });

import { css, Theme } from '@emotion/react';
import { kebabCase } from 'lodash';
import { TransitionDuration } from '../transitions';

/** Apply a transition to an element. */
export const transition = (properties: string | string[], duration: TransitionDuration = 'fast') => (theme: Theme) =>
  css({
    transition: (Array.isArray(properties) ? properties : [properties])
      .map((property) => kebabCase(property))
      .map((property) => `${property} ${theme.transitions[duration]}ms`)
      .join(','),
  });

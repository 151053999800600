import { EmotionIcon } from '@emotion-icons/emotion-icon';
import { useTheme } from '@emotion/react';
import { isNumber } from 'lodash';
import { useMemo } from 'react';
import { CssOverridableProps, TextColor, useStyles, useTextVariant } from '../../core';
import { fontColor, weakEffect } from '../../theme/mixins';

/** Props definition for an icon component. */
export type IconProps = {
  /** Color of the icon. */
  color?: TextColor;
  /** Icon in the form of an Emotion icon. */
  icon: EmotionIcon;
  /** The size of the icon using the configured scale. */
  size?: number;
  /** Whether the icon should have a less vibrant appearance. */
  weak?: boolean;
} & CssOverridableProps;

/** The icon component can be used to display Emotion icons in standardized sizes. */
export const Icon = ({ className, color = 'inherit', icon, size, weak = false }: IconProps) => {
  const { typography } = useTheme();

  // If used inside a text component we can use the text size to set a sensible default icon size.
  const textVariant = useTextVariant();

  const calculatedSize = useMemo(() => {
    return size ? size : textVariant ? typography.variants[textVariant].fontSize : 7;
  }, [size, textVariant]);

  const iconStyles = useStyles(
    (theme) => [
      fontColor(color),
      { height: isNumber(calculatedSize) ? theme.layout.scale(calculatedSize) : calculatedSize },
      weak && weakEffect(),
    ],
    [color, size, weak]
  );

  // To render the SVG icon as a component the icon variable needs to be capitalized.
  const SvgIcon = icon;

  return <SvgIcon className={className} css={iconStyles} />;
};

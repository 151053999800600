/** List of available font weights. */
export type Breakpoint = keyof typeof breakpoints;

/** A theme object for breakpoints. */
export const breakpoints = {
  xs: 480,
  sm: 700,
  md: 960,
  lg: 1280,
  xl: 1920,
};

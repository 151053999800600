import { css, Theme } from '@emotion/react';
import { ColorVariant } from '../colors';

/** Apply a background color to a non-interactive element. */
export const fillStatic = (color: ColorVariant) => ({ colors }: Theme) => {
  return css({ background: colors.highlight(color) });
};

/** Apply a background color to an outlined element, which can be both static and interactive. */
export const fillOutlined = () => {
  return css({ background: 'white' });
};

/** Apply a background color to a filled interactive element. */
export const fillInteractive = (color: ColorVariant) => ({ colors }: Theme) => {
  return css({ background: colors.active(color) });
};

/** Apply a background color to an element currently in an active state. */
export const fillActive = (color: ColorVariant) => {
  return fillInteractive(color);
};

/** Apply a background color to an element currently being interacted with. */
export const fillPressed = (color: ColorVariant) => ({ colors }: Theme) => {
  return css({ background: colors.pressed(color) });
};

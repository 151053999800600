import { useTheme } from '@emotion/react';
import { FC } from 'react';
import { ButtonState, CssOverridableProps, FocusRing, useStyles } from '../../core';
import {
  elevation,
  elevationPressed,
  fillInteractive,
  fillOutlined,
  fillPressed,
  fontColor,
  fontColorInverted,
  outlineActive,
  outlineInteractive,
  overlayEffect,
  padding,
  roundedCorners,
  transition,
} from '../../theme/mixins';
import { BaseButton } from './BaseButton';
import { ButtonProps, ButtonSize } from './Button';

/** Props definition for a button container component. */
export type ButtonContainerProps = Required<Pick<ButtonProps, 'color' | 'outlined' | 'size' | 'stretch'>> &
  ButtonState &
  CssOverridableProps;

/** Map of button heights for different button sizes. */
const heights: Record<ButtonSize, number> = { small: 12, large: 18 };

/** Map of horizontal paddings from edge of the button to the label for different button sizes. */
const paddings: Record<ButtonSize, number> = { small: 5, large: 8 };

/** A button container defines the clickable box of a button. */
export const ButtonContainer: FC<ButtonContainerProps> = ({
  buttonProps,
  children,
  className,
  color,
  disabled,
  focusVisible,
  hovered,
  outlined,
  pressed,
  size,
  stretch,
}) => {
  const { layout } = useTheme();

  const buttonStyles = useStyles(
    ({ layout }) => [
      padding(0, paddings[size]),
      roundedCorners(),
      { height: layout.scale(heights[size]) - (outlined ? 0 : 3) },
      stretch && { width: '100%' },
      outlined
        ? [
            fillOutlined(),
            fontColor(color),
            outlineInteractive(),
            transition(['background', 'boxShadow']),
            hovered && outlineActive(color),
            pressed && overlayEffect(1, color),
          ]
        : [
            elevation(color),
            fillInteractive(color),
            fontColorInverted(),
            transition(['background', 'boxShadow', 'margin', 'transform']),
            pressed && [elevationPressed(color), fillPressed(color)],
          ],
    ],
    [color, hovered, outlined, pressed, size]
  );

  // Styles for a wrapping a contained button, making sure the button keeps the same height even with an elevation.
  const buttonWrapperStyles = useStyles(({ layout }) => ({ height: layout.scale(heights[size]) }));

  // Define a bottom offset to make the elevation shadow visible while focusing contained buttons.
  const focusRingOffset = outlined || pressed ? 0 : [0, 0, `${layout.elevationSize}px`, 0];

  // We store the base button in a variable since we will be rendering outlined and contained buttons differently.
  const baseButton = (
    <FocusRing color={color} focused={focusVisible} offset={focusRingOffset}>
      <BaseButton {...buttonProps} className={className} css={buttonStyles} disabled={disabled}>
        {children}
      </BaseButton>
    </FocusRing>
  );

  return <>{outlined ? baseButton : <div css={buttonWrapperStyles}>{baseButton}</div>}</>;
};

import { EmotionIcon } from '@emotion-icons/emotion-icon';
import { FC, isValidElement, ReactNode } from 'react';
import {
  AccessibleLabelProps,
  CssOverridableProps,
  Item,
  Stack,
  Text,
  useButton,
  UseButtonProps,
  useStyles,
} from '../../core';
import { ColorVariant, TypographyVariant } from '../../theme';
import { Icon } from '../icon';
import { ProgressCircle, ProgressCircleSize } from '../progressCircle';
import { ButtonContainer } from './ButtonContainer';

/** Props definition for a button component. */
export type ButtonProps = {
  /** Color of the button. */
  color?: ColorVariant;
  /** A link's destination. If provided, wraps the button in an anchor element. */
  href?: string;
  /** Whether the button is in a loading state. */
  loading?: boolean;
  /** Whether the button should have an outlined appearance. Also known as a ghost button. */
  outlined?: boolean;
  /** Contents placed before the label. */
  prefix?: EmotionIcon | ReactNode;
  /** Size of the button. */
  size?: ButtonSize;
  /** Whether the button should stretch the width of its parent.. */
  stretch?: boolean;
  /** Contents placed after the label. */
  suffix?: EmotionIcon | ReactNode;
} & UseButtonProps &
  AccessibleLabelProps &
  CssOverridableProps;

/** List of available button sizes. */
export type ButtonSize = 'small' | 'large';

/** Map of typography variants for different button sizes. */
const labelVariants: Record<ButtonSize, TypographyVariant> = { small: 'body1', large: 'h5' };

/** Map of icon sizes for different button sizes. */
const iconSizes: Record<ButtonSize, number> = { small: 5, large: 6 };

/** Map of spacing between label text and icons for different button sizes. */
const labelSpacing: Record<ButtonSize, number> = { small: 3, large: 4 };

/** Map of progress circle sizes for different button sizes. */
const progressCircleSizes: Record<ButtonSize, ProgressCircleSize> = { small: 'small', large: 'medium' };

/** Buttons allow users to take actions, and make choices, with a single tap. */
export const Button: FC<ButtonProps> = ({
  className,
  color = 'primary',
  href,
  prefix,
  loading = false,
  outlined = false,
  size = 'small',
  stretch = false,
  suffix,
  ...otherProps
}) => {
  const { children, disabled } = otherProps;

  // The `useButton` hook handles interactions and accessibility for buttons.
  const buttonState = useButton({ ...otherProps, disabled: disabled || loading });

  // By visually hiding the label when the loading spinner appears we can maintain the same button width.
  const labelStyles = useStyles(loading && { opacity: 0, userSelect: 'none' }, [loading]);

  const progressCircleStyles = useStyles({
    left: '50%',
    position: 'absolute',
    transform: 'translateX(-50%)',
  });

  const button = (
    <ButtonContainer
      {...buttonState}
      className={className}
      color={color}
      outlined={outlined}
      size={size}
      stretch={stretch}
    >
      <Stack align="center" gap={labelSpacing[size]}>
        {prefix && (
          <Item>
            {isValidElement(prefix) ? prefix : <Icon icon={prefix as EmotionIcon} size={iconSizes[size]} weak />}
          </Item>
        )}
        <Item>
          <Text css={labelStyles} variant={labelVariants[size]} weight="medium">
            {children}
          </Text>
        </Item>
        {suffix && (
          <Item>
            {isValidElement(suffix) ? suffix : <Icon icon={suffix as EmotionIcon} size={iconSizes[size]} weak />}
          </Item>
        )}
      </Stack>
      {loading && <ProgressCircle css={progressCircleStyles} size={progressCircleSizes[size]} />}
    </ButtonContainer>
  );

  return href ? <a href={href}>{button}</a> : button;
};

import { jsx, useTheme } from '@emotion/react';
import { isArray, isNumber } from 'lodash';
import { Children, cloneElement, FC, isValidElement, useMemo } from 'react';
import { ColorVariant } from '../../../theme';
import { circular, roundedCorners, shadow, transition } from '../../../theme/mixins';
import { useStyles } from '../../hooks';
import { CssOverridableProps } from '../../types';

/** Props definition for a focus ring component. */
export type FocusRingProps = {
  /** Whether the focus ring should be circular. */
  circular?: boolean;
  /** Color of the focus ring. */
  color?: ColorVariant;
  /** Whether the focus ring should be visible. */
  focused: boolean;
  /** How much offset the focus ring should have. Number values use configured scale. */
  offset?: number | string | (number | string)[];
} & CssOverridableProps;

/** A focus ring wrapper component displaying a ring around the wrapped element. */
export const FocusRing: FC<FocusRingProps> = (props) => {
  const { color = 'primary', offset = 0, children, focused } = props;

  const theme = useTheme();

  // Normalize the offset prop to make sure it's always a string.
  const normalizedOffset = useMemo(() => {
    return (isArray(offset) ? offset : [offset])
      .map((value) => (isNumber(value) ? `${-theme.layout.scale(value)}px` : `-${value}`))
      .join(' ');
  }, [offset]);

  const focusRingStyles = useStyles(
    (theme) => ({
      position: 'relative',
      '&::before': [
        transition(['boxShadow', 'inset'])(theme),
        {
          boxShadow: 'none',
          content: '""',
          inset: normalizedOffset,
          position: 'absolute',
          zIndex: 1,
        },
        props.circular ? circular() : roundedCorners()(theme),
        props.focused && shadow(color)(theme),
      ],
    }),
    [props.circular, color, focused, normalizedOffset]
  );

  // Extract the first child and throws an error if there's more than one.
  const child = Children.only(children);

  // Also throw an error if the child is not a valid React element.
  if (!isValidElement(child)) {
    throw new Error('The focus ring component must contain one child element');
  }

  // Render the child element with a pseudo element to display a focus ring.
  return 'css' in child.props
    ? cloneElement(child, { css: [child.props.css, focusRingStyles] })
    : jsx(child.type, { key: child.key, ...child.props, css: focusRingStyles });
};

import { useTheme } from '@emotion/react';
import { toUpper } from 'lodash';
import Flag from 'react-flagkit';
import { CssOverridableProps, useStyles } from '../../core';

/** Props definition for a flag icon component. */
export type FlagIconProps = {
  /** The country code of the country to display a flag for. */
  countryCode: string;
  /** The size of the icon using the configured scale. */
  size?: number;
} & CssOverridableProps;

/** The flag icon component displays a flag from the given country code. */
export const FlagIcon = ({ className, countryCode, size = 7 }: FlagIconProps) => {
  const { layout } = useTheme();

  const iconStyles = useStyles(() => [{ borderRadius: 3, display: 'block', height: 'auto' }]);

  return <Flag className={className} country={toUpper(countryCode)} css={iconStyles} size={layout.scale(size)} />;
};

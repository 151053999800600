import { darken, tint } from 'polished';

/** List of available color hues. */
export type ColorHue = 100 | 300 | 500 | 700 | 900;

/** A theme object for the color palette. */
export const palette = {
  primary: color('#17A4FF', { tint: 0.6, shade: 0.15 }),
  success: color('#04D183', { tint: 0.5, shade: 0.1 }),
  warning: color('#F5AA39', { tint: 0.45, shade: 0.2 }),
  danger: color('#EF4444', { tint: 0.6, shade: 0.2 }),
  lightGray: color('#CAD0D8', { tint: 0.5, shade: 0.15 }),
  darkGray: color('#374151', { tint: 0.2, shade: 0.1 }),
  bureau: color('#123C69', { tint: 0.2, shade: 0.1 }),
};

/** A configuration of a color's hue levels. */
interface HueConfig {
  // A ratio between 0 and 1 defining the tint of the lightest hue.
  tint: number;
  // A ratio between 0 and 1 defining the shade of the darkest hue.
  shade: number;
}

/** Make a color of hues from a base color and hue configuration. */
function color(base: string, config: HueConfig): Record<ColorHue, string> {
  return {
    100: tint(config.tint, base),
    300: tint(config.tint * 0.5, base),
    500: base,
    700: darken(config.shade * 0.5, base),
    900: darken(config.shade, base),
  };
}

import { css, Theme } from '@emotion/react';
import { rgba } from 'polished';
import { ColorVariant } from '../colors';

/** Apply a disabled effect to an element. */
export const disabledEffect = () => {
  return css({ cursor: 'default', opacity: 0.4 });
};

/** Apply a faded out effect to an element. */
export const weakEffect = () => {
  return css({ opacity: 0.6 });
};

/** Apply an overlay effect to an element. */
export const overlayEffect = (multiplier = 1, color?: ColorVariant) => ({ colors }: Theme) => {
  return css({ background: rgba(colors.elevation(color ? color : 'darkGray'), 0.05 * multiplier) });
};

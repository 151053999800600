import { css, Theme } from '@emotion/react';
import { isNumber } from 'lodash';

/** Apply a spacing property to an element. */
const spacing = (property: 'padding' | 'margin', ...args: (number | string)[]) => ({ layout }: Theme) =>
  css({ [property]: args.map((value) => (isNumber(value) ? `${layout.scale(value)}px` : value)).join(' ') });

/** Apply a padding to an element. */
export const padding = (...args: (number | string)[]) => spacing('padding', ...args);

/** Apply a margin to an element. */
export const margin = (...args: (number | string)[]) => spacing('margin', ...args);

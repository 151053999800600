import { rgba } from 'polished';
import { Validity } from '../core';
import { Severity } from '../core/types/Severity';
import { palette } from './palette';

/** List of available color variants from the palette. */
export type ColorVariant = keyof typeof palette;

/** A theme object for colors. */
export const colors = {
  background: rgba(palette.lightGray[100], 0.25),
  staticBorder: palette.lightGray[100],
  interactiveBorder: palette.lightGray[700],
  active: (color: ColorVariant) => palette[color][500],
  pressed: (color: ColorVariant) => palette[color][700],
  elevation: (color: ColorVariant) => palette[color][900],
  shadow: (color: ColorVariant) => palette[color][100],
  highlight: (color: ColorVariant) => rgba(palette[color][100], 0.3),
  validity: (validity: Validity) => (validity === 'valid' ? 'primary' : validity === 'error' ? 'danger' : validity),
  severity: (severity: Severity) => (severity === 'info' ? 'primary' : severity),
  text: (color: ColorVariant) => palette[color][700],
};

import { keyframes, useTheme } from '@emotion/react';
import { isNil } from 'lodash';
import { FC } from 'react';
import { CssOverridableProps, TextColor, useStyles } from '../../core';
import { transition } from '../../theme/mixins';

/** Props definition for a progress circle component. */
export type ProgressCircleProps = {
  /** Color of the progress spinner. The `inherit` value is default and will use the current text color. */
  color?: TextColor;
  /** Size of the progress spinner. */
  size?: ProgressCircleSize;
  /** The value of the progress, between 0 and 1. If omitted, the spinner will be indeterminate. */
  value?: number;
} & CssOverridableProps;

/** List of available progress circle sizes. */
export type ProgressCircleSize = 'small' | 'medium' | 'large';

/** Animation to make an element spin around its own axis. */
const spin = keyframes({
  '0%': { transform: 'rotateZ(0deg)' },
  '100%': { transform: 'rotateZ(360deg)' },
});

/** Animation to shrink and grow the stroke of a circle. */
const shrinkAndGrow = (circumference: number) =>
  keyframes({
    '0%': { strokeDashoffset: circumference - 1, transform: 'rotate(0)' },
    '50%': { strokeDashoffset: circumference / 4, transform: 'rotate(45deg)' },
    '100%': { strokeDashoffset: circumference - 1, transform: 'rotate(360deg)' },
  });

/** Progress spinners are circular indicators of progress and activity. */
export const ProgressCircle: FC<ProgressCircleProps> = ({
  color = 'inherit',
  size = 'medium',
  children,
  className,
  value,
}) => {
  if (!isNil(value) && (value < 0 || value > 1)) {
    throw new Error(`Value [${value}] is not between 0 and 1`);
  }

  const { layout } = useTheme();

  const diameters = { small: layout.scale(6), medium: layout.scale(10), large: layout.scale(20) };
  const diameter = diameters[size];
  const strokeWidth = diameter / 10;
  const circumference = 2 * (diameter / 2 - strokeWidth / 2) * Math.PI;

  const circleStyles = useStyles(
    ({ colors }) => [
      transition(['stroke', 'strokeDashoffset'], 'medium'),
      {
        cx: diameter / 2,
        cy: diameter / 2,
        r: diameter / 2 - strokeWidth / 2,
        stroke: color === 'inherit' ? 'currentColor' : colors.active(color),
        strokeDasharray: circumference,
        strokeLinecap: 'round',
        transform: 'rotate(-90deg)',
        transformOrigin: 'center',
      },
      value
        ? { strokeDashoffset: value && circumference - value * circumference }
        : { animation: `${shrinkAndGrow(circumference)} 1.4s ease-in-out infinite both` },
    ],
    [circumference, diameter, strokeWidth, value]
  );

  const labelStyles = useStyles({
    inset: 0,
    display: 'flex',
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
  });

  return (
    <div className={className} css={{ position: 'relative' }}>
      <svg
        width={diameter}
        height={diameter}
        viewBox={`0 0 ${diameter} ${diameter}`}
        fill="none"
        css={{ display: 'block' }}
      >
        <g css={{ animation: isNil(value) ? `${spin} 2s linear infinite` : 'none', transformOrigin: 'center' }}>
          {value && (
            <circle
              css={[
                circleStyles,
                ({ colors }) => ({ stroke: colors.staticBorder, strokeDasharray: circumference * 2 }),
              ]}
              role="presentation"
              strokeWidth={strokeWidth}
            />
          )}
          <circle css={circleStyles} role="presentation" strokeWidth={strokeWidth} />
        </g>
      </svg>
      <div css={labelStyles}>{children}</div>
    </div>
  );
};

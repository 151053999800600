import { css, Theme } from '@emotion/react';
import { ColorVariant } from '../colors';

/** Helper function to make a box shadow for a border-like outline. */
const boxShadow = (color: string, size: number) => `inset 0 0 0 ${size}px ${color}`;

/** Apply an outline to a static element. */
export const outlineStatic = () => ({ colors, layout }: Theme) => {
  return css({ boxShadow: boxShadow(colors.staticBorder, layout.outlineSize) });
};

/** Apply an outline to an interactive element. */
export const outlineInteractive = (color?: ColorVariant) => ({ colors, layout }: Theme) => {
  return css({
    boxShadow: boxShadow(color ? colors.active(color) : colors.interactiveBorder, layout.outlineSize),
  });
};

/** Apply an outline to an element currently in an active state. */
export const outlineActive = (color: ColorVariant) => ({ colors, layout }: Theme) => {
  return css({ boxShadow: boxShadow(colors.active(color), layout.outlineSize) });
};

/** Apply an outline to an element currently being interacted with. */
export const outlinePressed = (color: ColorVariant) => ({ colors, layout }: Theme) => {
  return css({ boxShadow: boxShadow(colors.pressed(color), layout.outlineSize) });
};

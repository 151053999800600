import { breakpoints } from './breakpoints';
import { colors } from './colors';
import { layout } from './layout';
import { palette } from './palette';
import { transitions } from './transitions';
import { typography } from './typography';

/** A theme object for Nettbureau UI. */
export const theme = {
  breakpoints,
  colors,
  layout,
  palette,
  transitions,
  typography,
};

import { useTheme } from '@emotion/react';
import { intersection } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Breakpoint } from '../../theme/breakpoints';

/** State from the `useBreakpoint` hook providing utilities to help work with breakpoints. */
export type BreakpointsState = {
  /** Current active breakpoint. It will be null if below the lowest breakpoint. */
  breakpoint: Breakpoint | null;
  /** Helper function to get a value based on the current breakpoint. */
  responsive: <T>(baseValue: T, responsiveValues: Partial<Record<Breakpoint, T>>) => T;
};

/** Helper function to make a media query list object allowing you to listen for breakpoint changes. */
const toMediaQuery = (width: number) => window.matchMedia(`(min-width: ${width}px)`);

/** Hook for handling breakpoints in a responsive design. */
export const useBreakpoints = (): BreakpointsState => {
  const { breakpoints } = useTheme();

  /** Since objects cannot guarantee a given order, we keep an array of the breakpoints in descending order. */
  const sortedBreakpoints = useMemo<Breakpoint[]>(() => {
    return (Object.keys(breakpoints) as Breakpoint[]).sort((a, b) => breakpoints[b] - breakpoints[a]);
  }, [breakpoints]);

  /** List of media queries sorted in descending order. */
  const mediaQueries = useMemo<Partial<Record<Breakpoint, MediaQueryList>>>(() => {
    return sortedBreakpoints.reduce(
      (previous, breakpoint) => ({
        ...previous,
        [breakpoint]: toMediaQuery(breakpoints[breakpoint]),
      }),
      {}
    );
  }, [sortedBreakpoints]);

  /** Get a list of currently active breakpoints. */
  const activeBreakpoints = () => sortedBreakpoints.filter((breakpoint) => mediaQueries[breakpoint]?.matches);

  const [breakpoint, setBreakpoint] = useState<Breakpoint | null>(null);
  const updateBreakpoint = () => setBreakpoint(activeBreakpoints().length ? activeBreakpoints()[0] : null);

  useEffect(() => {
    updateBreakpoint();
    Object.values(mediaQueries).forEach((query) => query.addEventListener('change', updateBreakpoint));
    return () => Object.values(mediaQueries).forEach((query) => query.removeEventListener('change', updateBreakpoint));
  }, []);

  const responsive = useCallback<BreakpointsState['responsive']>((baseValue, responsiveValues) => {
    const breakpoints = intersection(activeBreakpoints(), Object.keys(responsiveValues) as Breakpoint[]);
    return responsiveValues[breakpoints[0]] ?? baseValue;
  }, []);

  return { breakpoint, responsive };
};

import { FC } from 'react';
import { useStyles } from '../../hooks';
import { CssOverridableProps } from '../../types';
import { convertToFlexValue } from './Stack';

/** Props definition for a stack item component. */
export type ItemProps = {
  /** Alignment of the stacked item. This overrides the stack component's `align` prop. */
  align?: ItemAlign;
  /** Whether the stack item should have a flex grow effect. */
  grow?: boolean;
  /** Width of the stacked item. */
  width?: number | string;
} & CssOverridableProps;

/** List of available values for aligning the stack item. */
export type ItemAlign = 'start' | 'center' | 'end';

/** A stacked item. Should be direct children of a stack component. */
export const Item: FC<ItemProps> = ({ align, children, className, grow, width }) => {
  const itemStyles = useStyles([
    align && { alignSelf: convertToFlexValue(align) },
    grow && { flexGrow: 1 },
    width && { width },
  ]);

  return (
    <div className={className} css={itemStyles}>
      {children}
    </div>
  );
};

import { ThemeProvider as EmotionThemeProvider } from '@emotion/react';
import { OverlayProvider } from '@react-aria/overlays';
import { ReactNode } from 'react';
import { theme } from '../theme';
import { GlobalStyles } from './GlobalStyles';

/** Props definition for the theme provider component. */
export type ThemeProviderProps = {
  /** Contents which the theme is applied to. */
  children: ReactNode;
};

/** Component providing the Nettbureau UI theme for its children. */
export const ThemeProvider = ({ children }: ThemeProviderProps) => (
  <EmotionThemeProvider theme={theme}>
    <GlobalStyles />
    <OverlayProvider>{children}</OverlayProvider>
  </EmotionThemeProvider>
);

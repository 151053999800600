import { modularScale } from 'polished';
import 'typeface-poppins';

/** List of available typography variants for text. */
export type TypographyVariant = keyof typeof typography['variants'];

/** List of available font weights. */
export type FontWeight = keyof typeof typography['weights'];

/** A theme object for base typography styles. */
const base = {
  baseSize: 16,
  fonts: {
    body: 'Poppins, sans-serif',
  },
  lineHeights: {
    body: 1.6,
    heading: 1.2,
  },
  weights: {
    normal: 400,
    medium: 500,
    bold: 600,
    bolder: 700,
  },
};

/** A theme object for typography. */
export const typography = {
  ...base,
  variants: {
    h1: {
      fontSize: scale(6),
      fontWeight: base.weights.bolder,
      letterSpacing: -1,
      lineHeight: base.lineHeights.heading,
    },
    h2: {
      fontSize: scale(5),
      fontWeight: base.weights.bolder,
      letterSpacing: -0.5,
      lineHeight: base.lineHeights.heading,
    },
    h3: {
      fontSize: scale(4),
      fontWeight: base.weights.bolder,
      lineHeight: base.lineHeights.heading,
    },
    h4: {
      fontSize: scale(3),
      fontWeight: base.weights.bolder,
      lineHeight: base.lineHeights.heading,
    },
    h5: {
      fontSize: scale(2),
      fontWeight: base.weights.bold,
    },
    h6: {
      fontSize: scale(1),
      fontWeight: base.weights.bold,
    },
    subtitle1: {
      fontSize: scale(2),
      fontWeight: base.weights.medium,
    },
    subtitle2: {
      fontSize: scale(1),
      fontWeight: base.weights.medium,
    },
    body1: {
      fontSize: scale(0.5),
    },
    body2: {
      fontSize: scale(0),
    },
    caption: {
      fontSize: scale(-0.25),
    },
  },
};

/** Get a font size from a modular scale based on the base font size. */
function scale(steps: number) {
  return modularScale(steps, '1rem', 1.25);
}

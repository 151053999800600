import { Global } from '@emotion/react';
import { fontColor } from '../mixins';

/** Global styles applying base styles and reset CSS to normalize browser inconsistencies. */
export const GlobalStyles = () => (
  <Global
    styles={(theme) => ({
      html: {
        // Include padding and border when using width or height by default.
        boxSizing: 'border-box',
        // Normalize the line height in all browsers.
        lineHeight: 1,
        // Prevent adjustments of font size after orientation changes in iOS.
        textSizeAdjust: '100%',
      },
      // Inherit border-box from the html selector above for all elements, but allow for individual overriding.
      '*, *::before, *::after': {
        boxSizing: 'inherit',
      },
      // Remove the gruesome default focus indicator.
      '*:focus': {
        outline: 'none',
      },
      body: [
        fontColor()(theme),
        {
          // Apply a default background color.
          background: theme.colors.background,
          // Apply default typography styles.
          fontFamily: theme.typography.fonts.body,
          fontSize: theme.typography.baseSize,
          lineHeight: theme.typography.lineHeights.body,
        },
      ],
      // Remove the margin in all browsers.
      'body, h1, h2, h3, h4, h5, h6': {
        margin: 0,
      },
      // Make sure all elements inherits the default typography styles.
      'button, input, optgroup, select, textarea': {
        fontFamily: 'inherit',
        fontSize: '100%',
        lineHeight: 1,
      },
      // Remove the default styling of anchor tags. Use a `Link` component for styled links.
      a: {
        textDecoration: 'none',
      },
    })}
  />
);

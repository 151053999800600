import { ButtonHTMLAttributes, FC } from 'react';
import { CssOverridableProps, useStyles } from '../../core';
import { disabledEffect } from '../../theme/mixins';

/** Props definition for a base button component. */
export type BaseButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & CssOverridableProps;

/** A button component providing base styles common for all button types. */
export const BaseButton: FC<BaseButtonProps> = ({ children, className, disabled, ...otherProps }) => {
  const buttonStyles = useStyles(
    [
      {
        alignItems: 'center',
        background: 'transparent',
        border: 'none',
        color: 'inherit',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        padding: 0,
      },
      disabled && disabledEffect(),
    ],
    [disabled]
  );

  return (
    <button {...otherProps} className={className} css={buttonStyles} disabled={disabled}>
      {children}
    </button>
  );
};

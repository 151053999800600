import { useFocusRing } from '@react-aria/focus';
import { useHover, usePress } from '@react-aria/interactions';
import { HTMLAttributes } from 'react';

/** Props definition for the `useInteractions` hook. */
export type UseInteractions = {
  /** Whether the interactive element element should be disabled. */
  disabled: boolean;
  /** Callback fired when the element is pressed. */
  onPress?: () => void;
};

/** State from the `useInteractions` hook providing all you need to create an interactive element. */
export type InteractionsState = {
  /** Props to be spread on the element that will be focused. */
  focusProps: HTMLAttributes<HTMLElement>;
  /** Props to be spread on the element that will be hovered. */
  hoverProps: HTMLAttributes<HTMLElement>;
  /** Props to be spread on the element that will be pressed. */
  pressProps: HTMLAttributes<HTMLElement>;
  /** Whether the input element is disabled. */
  disabled: boolean;
  /** Whether the input element has focus. */
  focused: boolean;
  /** Whether the input element has focus with keyboard. */
  focusVisible: boolean;
  /** Whether the input element is currently being hovered. */
  hovered: boolean;
  /** Whether the input element is currently being pressed. */
  pressed: boolean;
};

/** Hook for handling accessibility-friendly interactions with an interactive element. */
export function useInteractions(props: UseInteractions): InteractionsState {
  const { disabled = false, onPress } = props;
  const { focusProps, isFocused, isFocusVisible } = useFocusRing();
  const { hoverProps, isHovered } = useHover({ isDisabled: disabled });
  const { pressProps, isPressed } = usePress({
    isDisabled: disabled,
    onPress,
  });

  return {
    focusProps,
    hoverProps,
    pressProps,
    disabled,
    focused: isFocused,
    focusVisible: isFocusVisible,
    hovered: isHovered && !disabled,
    pressed: isPressed && !disabled,
  };
}

import { jsx } from '@emotion/react';
import { Children, cloneElement, FC, isValidElement } from 'react';
import { useStyles } from '../../hooks';
import { CssOverridableProps } from '../../types';

/** Props definition for a stack component. */
export type StackProps = {
  /** Alignment of the stacked items. */
  align?: StackAlign;
  /** Defines the space between stacked items. */
  gap?: number;
  /** Defines how the browser distributes space between and around all the items. */
  justify?: StackJustify;
  /** By default, the items are stacked horizontally. By setting `vertical` to true they will be stacked vertically. */
  vertical?: boolean;
} & CssOverridableProps;

/** List of available values for aligning the items of a stack component. */
export type StackAlign = 'start' | 'center' | 'end' | 'stretch';

/** List of available values for justifying the content of a stack component. */
export type StackJustify = 'start' | 'center' | 'end' | 'space-between';

/** Helper function to convert a prop to a valid flex value. */
export const convertToFlexValue = (value: string) => (['start', 'end'].includes(value) ? `flex-${value}` : value);

/** The stack component allows you to stack children along the vertical or horizontal axis. */
export const Stack: FC<StackProps> = ({
  children,
  align = 'start',
  gap = 0,
  justify = 'start',
  vertical = false,
  className,
}) => {
  const containerStyles = useStyles({
    alignItems: convertToFlexValue(align),
    display: 'flex',
    flexDirection: vertical ? 'column' : 'row',
    justifyContent: convertToFlexValue(justify),
  });

  const itemStyles = useStyles(
    (theme) => ({
      '&:not(:first-of-type)': {
        [`margin${vertical ? 'Top' : 'Left'}`]: theme.layout.scale(gap),
      },
    }),
    [gap]
  );

  const items = Children.toArray(children).filter(isValidElement);

  // In case we don't have any items to stack, we won't return anything.
  if (!items.length) {
    return null;
  }

  return (
    <div className={className} css={containerStyles}>
      {items.map((item: any) => {
        return 'css' in item.props
          ? cloneElement(item, { css: [item.props.css, itemStyles] })
          : jsx(item.type, { key: item.key, ...item.props, css: itemStyles });
      })}
    </div>
  );
};

import { css, Theme } from '@emotion/react';
import { ColorVariant } from '../colors';

/** Apply an elevation shadow to an element. */
export const elevation = (color: ColorVariant) => ({ colors, layout }: Theme) =>
  css({ boxShadow: `0 ${layout.elevationSize}px 0 ${colors.elevation(color)}` });

/** Remove an elevation shadow from an element currently being interacted with. */
export const elevationPressed = (color: ColorVariant) => ({ colors, layout }: Theme) =>
  css({
    boxShadow: `0 0 0 ${colors.elevation(color)}`,
    transform: `translateY(${layout.elevationSize}px)`,
  });
